(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbAuth')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('en', {
      'form.validation.required': 'Field is required',
      'form.validation.zero': 'Content can not be zero',
      'form.validation.minlength': 'Content is too short',
      'form.validation.maxlength': 'Content is too long',
      'form.validation.email': 'Invalid email address',
      'form.validation.match': 'Fields are not identical',
      'form.validation.login': 'Wrong ID or password',
      'form.validation.date': 'Please enter valid date',
      'form.validation.min': 'Content is too short',
      'form.validation.max': 'Content is too long',
      'form.validation.string': 'Content is not text format',
      'form.validation.bool': 'Content is not logical',
      'form.validation.url': 'Please enter valid URL',
      'form.validation.number': 'Content is not number',
      'form.validation.pattern': 'Content is not in the format required',
      'form.validation.password': 'Invalid password',
      'form.validation.emailExists': 'This email address is already in use',
      'form.validation.emailNotExists': 'This email address is not in our system',
      'form.validation.cardNotExists': 'There is no card matching this card number',
      'form.validation.fullNameMismatch': 'Invalid name',
      'form.validation.passwordResetLimit': 'Reseting password is currently unavailable. Try again later!',
      'form.validation.loginLimit': 'Login is currently unavailable. Try again later!',
      'form.validation.panMismatch': 'information of the card are not matching!',
      'form.validation.fileExtension': 'File extension invalid',
      'form.validation.fileLoad': 'File can not be loaded',
      'form.validation.fileEmpty': 'Uploaded file empty',
      'form.validation.fileColumns': 'File structure invalid according to the example',
      'form.validation.confirmationCode': 'Confirmation code invalid',
      'form.validation.passwordMismatch': 'Password invalid with this account',
      'form.validation.companyNameExists': 'Company name already exists',
      'form.validation.companyNumberExists': 'Company number does not exist in the system',
      'form.validation.companyVatNumberExists': 'VAT number already exists',
      'form.validation.fileData': 'Invalid content. Please fill in according to the XLS example!',
      'form.validation.fileTooLarge': 'Too long information in the XLS (Maximum 9999 cards)',
      'form.validation.companySearchLimit': 'Too much company search. Please try again later!',
      'form.validation.cardTypeMismatch': 'Card type invalid',
      'form.validation.mask': 'Content type is invalid',
      'form.validation.fileSize': 'File size exceeds the limit! (max 35Mb)',
      'form.validation.duplicate': 'Duplicated entries',
      'form.validation.multiwalletDuplicateEmail': 'Egy e-mail-címre egy Edenred Ticket Restaurant SZÉP Kártya igényelhető csak',
      'form.validation.contractNotEligible': 'No contract for this card type.',
      'form.validation.categoryMismatch': 'Card category does not match defined one during order.',
      'form.validation.topUpDisabled': 'This card type is not elligible for top up.',
      'form.validation.minDateOfBirth': 'Card cannot be requested under the age of 18.',
      'form.validation.permissionMissingForExtranet': 'You do not have access to the extranet'
    });
  }
}());
